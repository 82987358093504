import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulDomainServicesPage {
        nodes {
          heroShortTile
          heroTitle
          heroDescription {
            childContentfulRichText {
              html
            }
          }
          heroImage {
            file {
              url
              fileName
            }
          }
        }
      }
    }
  `)

  const heroSecData = data.allContentfulDomainServicesPage.nodes[0]

  return (
    <section id="domain-services-hero">
      <Container>
        <Row>
          <Col md={6} className="domain-services-hero-text">
            <Fade cascade duration={2500}>
              <h3
                dangerouslySetInnerHTML={{ __html: heroSecData.heroShortTitle }}
              />
              <h1 dangerouslySetInnerHTML={{ __html: heroSecData.heroTitle }} />
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    heroSecData.heroDescription.childContentfulRichText.html,
                }}
              />
            </Fade>
          </Col>
          <Col md={5} className="my-auto domain-services-hero-img offset-md-1">
            <Fade cascade duration={2500}>
              <img
                className="img-fluid"
                src={heroSecData.heroImage.file.url}
                alt={heroSecData.heroImage.file.fileName}
              />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeroSection
