import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DomainServices from "../components/domain-services"

const DomainServicesPage = ({ data }) => {
  const metaData = data.allContentfulDomainServicesPage.nodes[0]

  return (
    <Layout>
      <SEO title="We offer snapback for .se .nu .fi and .dk domains " description={metaData.metaDescription} />
      <DomainServices />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulDomainServicesPage {
      nodes {
        metaDescription
      }
    }
  }
`

export default DomainServicesPage
