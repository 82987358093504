import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Field, Form } from "formik"
import "./_index.scss"

const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),
  phone: Yup.string().required("Phone Number is Required"),
})

const initialValues = {
  "bot-field": "",
  "form-name": "contact-form",
  name: "",
  email: "",
  phone: "",
  message: "",
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmit = payload => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...payload }),
    })
      .then(() => setFormSubmitted(true))
      .catch(error => alert(error))
  }

  return (
    <>
      <div
        className={`text-center modal-head-wrapper ${
          formSubmitted ? "success-modal" : ""
        }`}
      >
        <img
          src={require("../../../../../static/icons/contact-modal-icon.svg")}
          alt="Icon"
        />
        {formSubmitted ? (
          <div className="modal-form-succss">
            <h1>
              Thank you! <br /> We will contact you soon
            </h1>
          </div>
        ) : (
          <h1>Get in touch</h1>
        )}
      </div>
      {!formSubmitted && (
        <div className="modal-form-wrapper">
          <Formik
            initialValues={initialValues}
            name="contact-form"
            validationSchema={ContactSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form
                data-netlify="true"
                name="contact-form"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact-form" />

                <div className="form-group">
                  <Field
                    name="name"
                    type="text"
                    placeholder="Name"
                    className={
                      errors.name && touched.name
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback">{errors.name}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email"
                    className={
                      errors.email && touched.email
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {errors.email && touched.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <Field
                    name="phone"
                    type="text"
                    placeholder="Phone number"
                    className={
                      errors.phone && touched.phone
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {errors.phone && touched.phone ? (
                    <div className="invalid-feedback">{errors.phone}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <Field
                    name="message"
                    component="textarea"
                    placeholder="Message"
                    rows="4"
                    className="form-control"
                  />
                </div>
                <button
                  type="submit"
                  className="btn submit-btn w-100"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending" : "Send"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  )
}

export default ContactForm
