import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import "./_index.scss"
import ContactModal from "../modal"

const ServicesSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulDomainServicesPage {
        nodes {
          firstServiceImage {
            file {
              fileName
              url
            }
          }
          firstServiceTitle
          firstServiceList {
            childContentfulRichText {
              html
            }
          }
          secondServiceImage {
            file {
              url
              fileName
            }
          }
          secondServiceTitle
          secondServiceDescription {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  `)

  const servicesData = data.allContentfulDomainServicesPage.nodes[0]

  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <section id="domain-services-sec">
        <Container>
          <Row className="justify-content-center">
            <Col lg={5} md={6} className="px-lg-5 domain-serivces-first-col">
              <div className="domain-service-wrap">
                <Fade cascade duration={2500}>
                  <div className="domain-service-inner-wrap">
                    <img
                      src={servicesData.firstServiceImage.file.url}
                      alt={servicesData.firstServiceImage.file.fileName}
                    />

                    <h2
                      dangerouslySetInnerHTML={{
                        __html: servicesData.firstServiceTitle,
                      }}
                    />

                    <div
                      className="domain-service-list"
                      dangerouslySetInnerHTML={{
                        __html:
                          servicesData.firstServiceList.childContentfulRichText
                            .html,
                      }}
                    />
                  </div>
                  <img
                    className="w-100 bottom-rectangle"
                    src={require("../../../../static/domain-services-rectangle.svg")}
                    alt=""
                  />
                </Fade>
              </div>
            </Col>

            <Col lg={5} md={6} className="px-lg-5 domain-serivces-second-col">
              <div className="domain-service-wrap h-100">
                <Fade cascade duration={2500}>
                  <div className="domain-service-inner-wrap">
                    <img
                      src={servicesData.secondServiceImage.file.url}
                      alt={servicesData.secondServiceImage.file.fileName}
                    />

                    <h2
                      dangerouslySetInnerHTML={{
                        __html: servicesData.secondServiceTitle,
                      }}
                    />

                    <div
                      className="domain-service-list"
                      dangerouslySetInnerHTML={{
                        __html:
                          servicesData.secondServiceDescription
                            .childContentfulRichText.html,
                      }}
                    />
                  </div>
                  <img
                    className="w-100 bottom-rectangle"
                    src={require("../../../../static/domain-services-rectangle.svg")}
                    alt=""
                  />
                </Fade>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <Button className="modal-btn" onClick={() => setModalShow(true)}>
                Get in touch
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <ContactModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default ServicesSection
