import React from "react"
import { Modal } from "react-bootstrap"
import ContactForm from "./form"
import "./_index.scss"

const ContactModal = props => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <ContactForm />
      </Modal.Body>
    </Modal>
  )
}

export default ContactModal
